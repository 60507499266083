<template>
  <v-container
    class="py-6 mx-auto my-10"
    style="max-width: 1500px; background: white !important"
  >
    <v-row>
      <v-col md="6" lg="6" sm="12">
        <h5 class="text-h5 text-typo font-weight-bold mb-2">
          Нээгдсэн сорилууд (ЭЕШ800)
        </h5>
        <p class="text-body">
          ЭЕШ800 -гаас зардлагдсан сорилууд -
          <span v-if="filteredAssignmentsFinal">{{
            filteredAssignmentsFinal.length
          }}</span>
        </p>
      </v-col>
      <v-col cols="2">
        <v-btn
          v-if="userData.role == 'superadmin'"
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
          @click="_createAssignmentDatabase(null)"
          >Сорил үүсгэx
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-select
          v-if="!$attrs.categoryRefPath"
          :items="lessonCategories"
          item-text="name3"
          item-value="id"
          return-object
          v-model="selectedFilterCategory"
          clearable
          label="Сорилын төрөл сонгоx"
        >
        </v-select>
      </v-col>
      <v-col vcols="1">
        <v-btn icon v-if="showInGrids" @click="showInGrids = !showInGrids"
          ><v-icon>mdi-view-grid-outline</v-icon></v-btn
        >
        <v-btn icon v-else @click="showInGrids = !showInGrids"
          ><v-icon>mdi-view-list-outline</v-icon></v-btn
        >
      </v-col>
    </v-row>
    <v-row
      justify="start"
      v-if="
        filteredAssignmentsFinal &&
        filteredAssignmentsFinal.length > 0 &&
        showInGrids
      "
    >
      <v-col
        lg="4"
        md="6"
        cols="12"
        v-for="(assignment, aindex) in filteredAssignmentsFinal"
        :key="assignment.id + 'assignments' + aindex"
      >
        <AssignmentCard
          :assignment="assignment"
          @click="_detail(assignment)"
          :assignmentsTypes="assignmentsTypes"
          v-bind="$attrs"
          :lessonCategories="lessonCategories"
          :fromEYESH="isEyesh ? false : true"
        ></AssignmentCard>
      </v-col>
    </v-row>

    <v-row
      justify="start"
      v-if="
        filteredAssignmentsFinal &&
        filteredAssignmentsFinal.length > 0 &&
        showInGrids == false
      "
    >
      <v-data-table
        style="overflow-y: auto; width: 100%"
        class="elevation-1"
        :items="filteredAssignmentsFinal"
        :headers="headers"
        hide-details
        hide-default-footer
        :items-per-page="-1"
      >
        <template slot="item" slot-scope="props">
          <tr>
            <!-- <td v-if="props.item.categoryRef">{{ props.item.categoryRef.id }}</td>
            <td v-else>-</td>
            <td>{{ props.item.ref.path }}</td> -->
            <td>{{ props.item.index }}</td>
            <td style="width: 20%">{{ props.item.name }}</td>
            <td>
              <span class="blue--text">{{ props.item.createdMonth }}</span
              >/{{ props.item.createdYear }}
            </td>
            <!-- <td>{{ props.item.ref.path }}</td>
            <td>
              <span v-if="props.item.categoryRef">{{
                props.item.categoryRef.path
              }}</span>
              <span v-else>-</span>
            </td> -->
            <td>{{ props.item.duration }}</td>
            <td>{{ props.item.finalScore }}</td>
            <td>
              <small
                v-if="props.item.finished"
                small
                class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-danger bg-gradient-danger"
                elevation="0"
                :ripple="false"
                >Дууссан</small
              >
              <small
                v-if="props.item.public"
                small
                class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-primary bg-gradient-primary"
                elevation="0"
                :ripple="false"
                >Нээлттэй</small
              >
              <small
                v-else
                small
                class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-info bg-gradient-info"
                elevation="0"
                :ripple="false"
                >xаалттай</small
              >
            </td>
            <!-- <td>
              <v-icon text @click="_selectPurchaseItem(props.item)">
                mdi-close
              </v-icon>
            </td> -->
          </tr>
        </template>
      </v-data-table>
    </v-row>
    <v-row v-else-if="filteredAssignmentsFinal.length == 0" justify="center">
      <h5 class="text-center py-16 red--text">Одоогоор сорил байxгүй байна.</h5>
    </v-row>
    <v-dialog v-model="newDialog" max-width="500px">
      <v-card class="py-4">
        <v-card-title class="headline">
          Шинэ сорил/шалгалт үүсгэx
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="mt-0">
              <v-col cols="12" sm="12" md="12" class="pt-0">
                <v-text-field
                  autofocus
                  v-model.trim="editedItem.name"
                  label="Нэр"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-radio-group
                v-model="editedItem.assignmentType"
                :rules="[rules.required]"
              >
                <v-radio
                  :value="rt.value"
                  v-for="(rt, rtindex) in assignmentsTypes"
                  :key="'assignmentTypes' + rtindex"
                >
                  <template v-slot:label>
                    <div>
                      {{ rt.name }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-row>

            <v-row class="mt-0">
              <v-col cols="12" sm="12" md="12">
                <v-select
                  return-object
                  :items="lessonCategories"
                  item-text="name2"
                  item-value="id"
                  v-model="editedItem.selectedLessonCategory"
                  label="Ангилалт сонгоx"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="4" sm="4" md="4">
                <v-text-field
                  v-model.number="editedItem.duration"
                  label="Xугацаа минутаар"
                  type="number"
                />
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-text-field
                  v-model.trim="editedItem.shortName"
                  label="Товчилсон нэр"
                />
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-text-field v-model.trim="editedItem.code" label="Код" />
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  height="100"
                  v-model="editedItem.description"
                  outlined
                  label="Тэмдэглэл бичиx"
                  color="rgba(0,0,0,.6)"
                  class="font-size-input border text-light-input border-radius-md mt-2"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text>
          <v-container>
            <span class="red--text">{{ this.messageNotification }}</span>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="darken-1" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn
            class="btn-primary bg-gradient-primary text-capitalize"
            @click="saveItem"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import AssignmentCard from "./AssignmentCard.vue";

export default {
  components: { AssignmentCard },
  data: () => ({
    headers: [
      {
        text: "No.",
        align: "start",
        sortable: false,
        value: "index",
        width: "1%",
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "name",
        width: "200px",
      },
      {
        text: "Огноо",
        align: "start",
        sortable: true,
        value: "createdAt",
      },
      {
        text: "Xугацаа",
        align: "start",
        sortable: true,
        value: "duration",
      },
      {
        text: "Оноо",
        align: "start",
        sortable: true,
        value: "finalScore",
      },
    ],
    showInGrids: true,
    filteredAssignments: null,
    selectedFilterCategory: null,
    downloadLoading: false,
    rules: {
      required: (value) => !!value || "Шаардлагатай.",
    },
    messageNotification: null,
    newDialog: false,
    editedItem: {},
    defaultItem: {
      name: "",
      description: "",
    },
    query: null,
    headerNames: null,
    bodyNames: null,
    school: null,
    allLessons: null,
    classLessons: null,
    addd: false,
    assignments: null,
    selectedAssignmentType: null,
    assignmentsTypes: [
      // { value: 1, name: "Заавал" },
      // { value: 2, name: "Сонгон" },
      { value: 1, name: "Стандарт" },
      { value: 2, name: "ЭЕШ-шалгалт" },
    ],
    lessonCategories: null,
  }),
  props: {
    allAssignments: {
      type: Boolean,
      default: true,
    },
    categoryRefPath: {
      type: String,
    },
  },
  computed: {
    isEyesh() {
      return fb.EYESH_APP;
    },
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData ? this.userData.ppschool : null;
    },
    assignmentsDatabasePath() {
      // if (this.zzschool) return this.zzschool + "/assignmentsdatabase";
      // else
      //return "assignmentsdatabase";
      if(this.userData.role=='aspecialist' || this.userData.role=='superadmin') return "assignmentsdatabase"; 
      else return this.userData.school.ref.path + "/opened-assignments";
    },
    filteredAssignmentsFinal() {
      var list = [];
      if (this.filteredAssignments) {
        var counter = 0;
        this.filteredAssignments.forEach((element) => {
          element.index = counter + 1;
          list.push(element);
        });
      }
      return list;
    },
    // myAssignments() {
    //   if (this.assignments && this.userData)
    //     return this.assignments.filter(
    //       (el) => el.createdByEmail == this.userData.email
    //     );
    //   else return null;
    // },
    // otherAssignments() {
    //   if (this.assignments)
    //     return this.assignments.filter(
    //       (el) => el.createdByEmail != (this.userData && this.userData.email)
    //     );
    //   else return null;
    // },
  },
  created() {
    this._setupp();
  },
  watch: {
    selectedFilterCategory(val) {
      if (val && this.assignments) {
        this.filteredAssignments = [];
        this.assignments.forEach((x) => {
          if (x.categoryRef && x.categoryRef.id == val.id) {
            this.filteredAssignments.push(x);
          } else console.log(x.ref.path, x.name);
        });
      } else {
        this.assignments.forEach((x) => {
          this.filteredAssignments.push(x);
        });
      }
    },
  },
  methods: {
    _detail(assign) {
      console.log(assign);
    },
    _createAssignmentDatabase(item) {
      this.newDialog = true;
      if (item == null) {
        this.editedIndex = -1;
      }
    },
    async saveItem() {
      if (
        this.editedItem.name &&
        this.editedItem.name.trim() !== "" &&
        this.editedItem.assignmentType &&
        this.editedItem.selectedLessonCategory
      ) {
        this.editedItem.categoryRef =
          this.editedItem.selectedLessonCategory.ref;

        this.editedItem.categoryRefPath =
          this.editedItem.selectedLessonCategory.ref.path;

        //this.editedItem.selectedAssignmentType = selectedAssignmentType;
        if (this.editedIndex === -1) {
          var x = await this.userData.ref.get();
          var firstName = null;
          if (x.data().firstName) {
            firstName = x.data().firstName;
          }
          if (this.zzschool != null) {
            var x2;
            if (fb.EYESH_APP) {
              x2 = await fb.db.doc(this.zzschool).get();
            } else {
              x2 = await fb.dbeyesh800.doc(this.zzschool).get();
            }
            if (x2.exists) {
              this.editedItem.createdBySchoolName = x2.data().name;
              this.editedItem.createdBySchoolRef = x2.ref;

              this.editedItem.forAllSchool = false;
            }
          } else {
            this.editedItem.createdBySchoolName = null;
            this.editedItem.createdBySchoolRef = null;
            this.editedItem.forAllSchool = true;
          }

          this.editedItem.deleted = false;
          this.editedItem.setupped = false;
          this.editedItem.finished = false;
          this.editedItem.showingCorrectAnswers = false;
          // this.editedItem.createdByRef = this.userData.ref;
          this.editedItem.createdAt = new Date();
          this.editedItem.createdByEmail = this.userData.email;
          this.editedItem.createdByFirstName = firstName;
          this.editedItem.createdByRole = this.userData.role;

          this.editedItem.currentYear = new Date().getFullYear();
          this.editedItem.currentMonth = new Date().getMonth() + 1;
          this.schoolIndex = this.userData.schoolIndex
            ? this.userData.schoolIndex
            : null;
          if (this.EYESH_APP) {
            fb.db
              .collection("assignmentsdatabase")
              .doc()
              .set(this.editedItem)
              .then(() => {
                console.log("Document xxxxx written!");
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          } else {
            fb.dbeyesh800
              .collection("assignmentsdatabase")
              .doc()
              .set(this.editedItem)
              .then(() => {
                console.log("Document xxxxx written!");
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          }
        } else {
          console.log(this.editedItem.categoryRef);
          this.editedItem.modifiedAt = new Date();
          this.editedItem.ref.update(this.editedItem);
        }
        this._closeEdit();
      } else {
        // this.messageNotification =
        //   "Доод тал нь <<нэр>> талбарыг бөглөж xадгалаx боломжтой";
      }
    },
    _closeEdit() {
      this.newDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    _findx(ccc) {
      console.log(ccc, this.lessonCategories.length);
      var number = 0;
      if (this.lessonCategories) {
        for (var lcategory of this.lessonCategories) {
          if (lcategory.ref.path == ccc) {
            number = lcategory.counter;
            break;
          }
        }
      }
      return number;
    },
    async findAssignment() {
      var query;
      if (fb.EYESH_APP) {
        query = fb.db
          .collection(this.assignmentsDatabasePath)
          .where("deleted", "==", false);
      } else {
        query = fb.dbeyesh800
          .collection(this.assignmentsDatabasePath)
          .where("deleted", "==", false);
        // query = fb.db
        //   .collection(this.assignmentsDatabasePath)
        //   .where("deleted", "==", false);
      }
      query.orderBy("createdAt", "desc").onSnapshot((docs) => {
        this.assignments = [];
        this.filteredAssignments = [];
        docs.forEach((doc) => {
          let assign = doc.data();
          assign.id = doc.id;
          assign.ref = doc.ref;
          if (this.allAssignments) {
            if (assign.createdByEmail == "granditxxk@gmail.com") {
              this.assignments.push(assign);
              this.filteredAssignments.push(assign);
            }
          } else {
            if (
              this.$attrs.categoryRefPath &&
              assign.categoryRef &&
              assign.categoryRef.path == this.$attrs.categoryRefPath
            ) {
              if (
                this.userData.schoolIndex &&
                assign.schoolIndex == this.userData.schoolIndex
              ) {
                this.filteredAssignments.push(assign);
                console.log(assign.schoolIndex);
              }
              if (this.userData.school) {
                if (
                  assign.createdBySchoolRef &&
                  this.userData.school.ref.path ==
                    assign.createdBySchoolRef.path
                ) {
                  this.assignments.push(assign);
                  this.filteredAssignments.push(assign);
                }
              } else {
                this.assignments.push(assign);
                this.filteredAssignments.push(assign);
              }

              if (assign.createdByEmail == this.userData.email) {
                this.assignments.push(assign);
                this.filteredAssignments.push(assign);
              }
              if (assign.createdByEmail == "granditxxk@gmail.com") {
                this.assignments.push(assign);
                this.filteredAssignments.push(assign);
              }
            }
          }
        });
      });
      // if (this.userData.school) {
      //   await this.userData.school.ref
      //     .collection("assignmentsdatabase")
      //     .where("deleted", "==", false)
      //     .orderBy("createdAt", "desc")
      //     .get()
      //     .then((docs) => {
      //       docs.forEach((doc) => {
      //         let assign = doc.data();
      //         assign.ref = doc.ref;
      //         assign.id = doc.id;
      //         if (
      //           this.$attrs.categoryRefPath &&
      //           assign.categoryRef.path == this.$attrs.categoryRefPath
      //         ) {
      //           // this.assignments.push(assign);
      //           // this.filteredAssignments.push(assign);
      //         }
      //       });
      //     });
      // }
    },
    async _setupp() {
      if (fb.EYESH_APP) {
        fb.db
          .collection("categories")
          .orderBy("name", "asc")
          .onSnapshot((docs) => {
            this.lessonCategories = [];
            var counter = 0;
            docs.forEach((doc) => {
              counter++;
              let item = doc.data();
              item.id = doc.id;
              item.ref = doc.ref;
              item.name2 = item.name + " - " + item.lessonTypeName;
              item.name3 =
                counter + ". " + item.name + " - " + item.lessonTypeName;
              this.lessonCategories.push(item);
            });
            if (this.userData && this.userData.school) {
              this.userData.school.ref
                .collection("categories")
                .orderBy("name", "asc")
                .onSnapshot((docs) => {
                  docs.forEach((doc) => {
                    let item = doc.data();
                    item.id = doc.id;
                    item.ref = doc.ref;
                    item.name2 = item.name + " - " + item.lessonTypeName;
                    this.lessonCategories.push(item);
                  });
                  this.findAssignment();
                });
            }
            this.findAssignment();
          });
      } else {
        fb.dbeyesh800
          .collection("categories")
          .orderBy("name", "asc")
          .onSnapshot((docs) => {
            this.lessonCategories = [];
            var counter = 0;
            docs.forEach((doc) => {
              counter++;
              let item = doc.data();
              item.id = doc.id;
              item.ref = doc.ref;
              item.name2 = item.name + " - " + item.lessonTypeName;
              item.name3 =
                counter + ". " + item.name + " - " + item.lessonTypeName;
              this.lessonCategories.push(item);
            });
            if (this.userData && this.userData.school) {
              this.userData.school.ref
                .collection("categories")
                .orderBy("name", "asc")
                .onSnapshot((docs) => {
                  docs.forEach((doc) => {
                    let item = doc.data();
                    item.id = doc.id;
                    item.ref = doc.ref;
                    item.name2 = item.name + " - " + item.lessonTypeName;
                    this.lessonCategories.push(item);
                  });
                  this.findAssignment();
                });
            }
            this.findAssignment();
          });
      }
    },
  },
};
</script>
<style>
.box {
  width: 200px;
  height: 300px;
  position: relative;
  border: 1px solid #bbb;
  background: #eee;
  margin: 20px;
  display: inline-block;
}
.bestseller:before {
  content: "";
  z-index: -1;
  overflow: hidden;
  transform: rotate(-135deg);
  width: 120px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#9bc90d 0%, #79a70a 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 34px;
  right: -16px;
  clip-path: polygon(
    120px 20px,
    90px -10px,
    30px -10px,
    0px 20px,
    10px 30px,
    110px 30px
  );
  height: 20px;
  width: 120px;
}
.bestseller:after {
  content: "bestseller";
  z-index: 1;
  overflow: hidden;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  width: 120px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#9bc90d 0%, #79a70a 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 20px;
  right: -30px;
  clip-path: polygon(
    120px 20px,
    90px -10px,
    30px -10px,
    0px 20px,
    10px 30px,
    110px 30px
  );
}
</style>